import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db, analytics } from "../firebase";
import Header from "../components/AppBar";
import DreamForm from "../components/DreamForm";
import LoadingScreen from "../components/LoadingScreen";
import SavedDreamDetail from "../components/SavedDreamDetail";
import MyDreams from "../components/MyDreams";
import PurchaseCoinsModal from "../components/PurchaseCoinsModal";
import { fetchUserData } from "../utils/dashboardUtils";
import { doc, onSnapshot } from "firebase/firestore";
import "../styles/dashboard.css";
import { logEvent } from "firebase/analytics";


const Dashboard = () => {
  const [userEmail, setUserEmail] = useState("");
  const [coins, setCoins] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dream, setDream] = useState("");
  const [error, setError] = useState("");
  const [analyzing, setAnalyzing] = useState(false);
  const [savedDreams, setSavedDreams] = useState([]);
  const [selectedDream, setSelectedDream] = useState(null);
  const [activeView, setActiveView] = useState("DreamForm"); // State for managing views
  const [viewKey, setViewKey] = useState(Date.now()); // Add key state for forcing re-render
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserData(navigate, setUserEmail, setCoins, setSavedDreams, setLoading);
  }, [navigate]);

  useEffect(() => {
    if (auth.currentUser) {
      const userDocRef = doc(db, "users", auth.currentUser.uid);

      const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();

          // Update coins in real time
          if (userData.coins !== coins) {
            setCoins(userData.coins);
          }
        }
      });

      return () => unsubscribe(); // Clean up the listener on component unmount
    }
  }, [coins]);

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'Dashboard Page',
    });
  }, []);
  

  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("userEmail");
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleViewChange = (view, key, resetSelectedDream = false) => {
    if (resetSelectedDream) {
      setSelectedDream(null); // Clear selected dream if needed
    }
    setActiveView(view);
    setViewKey(key); // Update the key to force re-render
  };

  const renderActiveView = () => {
    if (selectedDream) {
      return (
        <SavedDreamDetail
          key={viewKey}
          dream={selectedDream}
          onBack={() => setSelectedDream(null)}
          onDelete={(dream) =>
            setSavedDreams((prevDreams) =>
              prevDreams.filter((d) => d !== dream)
            )
          }
        />
      );
    }

    switch (activeView) {
      case "DreamForm":
        return (
          <DreamForm
            key={viewKey}
            dream={dream}
            onChange={(e) => setDream(e.target.value)}
            analyzing={analyzing}
            setAnalyzing={setAnalyzing}
            error={error}
            onErrorClose={() => setError("")}
            setError={setError}
            coins={coins}
            setCoins={setCoins}
            savedDreams={savedDreams}
            setSavedDreams={setSavedDreams}
            setSelectedDream={setSelectedDream}
            setActiveView={setActiveView}
          />
        );
      case "MyDreams":
        return (
          <MyDreams
            key={viewKey}
            savedDreams={savedDreams}
            handleCardClick={(dream) => setSelectedDream(dream)}
          />
        );
      case "PurchaseCoins":
        return (
          <PurchaseCoinsModal
            key={viewKey}
            onClose={() => setActiveView("DreamForm")}
          />
        );
      default:
        return null;
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  const userInitial = userEmail.charAt(0).toUpperCase();

  return (
    <div className="dashboard">
      <Header
        coins={coins}
        userInitial={userInitial}
        onLogout={handleLogout}
        onViewChange={handleViewChange} // Pass handleViewChange
      />
      <div className="dashboard-content">{renderActiveView()}</div>
    </div>
  );
};

export default Dashboard;
