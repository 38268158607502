import React, { useState,useEffect } from "react";
import "../styles/PurchaseCoinsModal.css";
import { auth} from "../firebase";
import { useStripe } from "@stripe/react-stripe-js";
import { ClipLoader } from "react-spinners";
import { analytics } from "../firebase";
import { logEvent } from "firebase/analytics";
import axios from "axios";


const PurchaseCoinsModal = () => {
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false); // Track global loading state


  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'Purchase Page',
    });
  }, []);


  const handlePurchase = async (priceId, coinsToAdd) => {
    setIsLoading(true);
    const user = auth.currentUser;
    if (!user) {
      console.error("User not authenticated");
      return;
    }
  
    const idToken = await user.getIdToken();
  
    try {
      const response = await axios.post(
        "https://us-central1-sapnasis.cloudfunctions.net/api/createCheckoutSession",
        {
          priceId,
          coins: coinsToAdd,
          successUrl: `${window.location.origin}/dashboard?success=true`,
          cancelUrl: `${window.location.origin}/dashboard`,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
  
      const { sessionId } = response.data;
      const result = await stripe.redirectToCheckout({ sessionId });
  
      if (result.error) {
        console.error(result.error.message);
      }
    } catch (error) {
      console.error("Error creating Stripe checkout session:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="modal-content">
      <h2 className="modal-title">Pirkti Žetonus</h2>
      <p className="modal-description">Pasirinkite jums norimą paketą, kad toliau galėtumėte analizuoti sapnus</p>
      <div className="package-container">
        <div className="package-card">
          <h3>Mini</h3>
          <p>100 žetonų</p>
          <button
            onClick={() => handlePurchase("price_1PLhgpDSm3CeQT7opwmt2oU5", 100)}
            disabled={isLoading}
          >
            {isLoading ? <ClipLoader size={20} color="#fff" /> : "€4.99"}
          </button>
        </div>
        <div className="package-card">
          <h3>Medi</h3>
          <p>500 žetonų</p>
          <button
            onClick={() => handlePurchase("price_1PLhgnDSm3CeQT7oIZfYZJia", 500)}
            disabled={isLoading}
          >
            {isLoading ? <ClipLoader size={20} color="#fff" /> : "€19.99"}
          </button>
        </div>
        <div className="package-card">
          <h3>Maxi</h3>
          <p>1650 žetonų</p>
          <button
            onClick={() => handlePurchase("price_1PLhgkDSm3CeQT7oPeFlh4Ap", 1650)}
            disabled={isLoading}
          >
            {isLoading ? <ClipLoader size={20} color="#fff" /> : "€49.99"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseCoinsModal;
