import { doc, getDoc, runTransaction } from "firebase/firestore";
import { auth, db } from "../firebase"; // Ensure functions is correctly imported
import axios from "axios";

export const fetchUserData = async (
  navigate,
  setUserEmail,
  setCoins,
  setSavedDreams,
  setLoading
) => {
  const fakeLoadingTime = 3200; // Minimum loading time in milliseconds
  const startTime = Date.now(); // Record the start time for the loading duration

  const retryFetch = async (retries) => {
    if (retries === 0) {
      console.error("No such document after retries!");
      const elapsedTime = Date.now() - startTime;
      const remainingTime = fakeLoadingTime - elapsedTime;
      setTimeout(() => setLoading(false), Math.max(remainingTime, 0)); // Ensure fake loading time
      return;
    }

    try {
      const user = await new Promise((resolve) => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
          unsubscribe();
          resolve(user);
        });
      });

      if (!user) {
        navigate("/login");
        return;
      }

      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserEmail(userData.email);
        setCoins(userData.coins);
        setSavedDreams(userData.dreams || []);
        const elapsedTime = Date.now() - startTime;
        const remainingTime = fakeLoadingTime - elapsedTime;
        setTimeout(() => setLoading(false), Math.max(remainingTime, 0)); // Ensure fake loading time
      } else {
        console.warn("Document not found. Retrying...");
        setTimeout(() => retryFetch(retries - 1), 500); // Retry after 500ms
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      const elapsedTime = Date.now() - startTime;
      const remainingTime = fakeLoadingTime - elapsedTime;
      setTimeout(() => setLoading(false), Math.max(remainingTime, 0)); // Ensure fake loading time
    }
  };

  await retryFetch(5); // Retry up to 5 times
};

const createValidationPrompt = (dream) => {
  return `Ar šis tekstas yra sapno aprašymas? Sapnas tai miegančiam kylantys vaizdai, mintys. 
Jei tai nėra sapno aprašymas arba yra kažkas kito, atsakyk "ne". Jei tai yra sapno aprašymas, atsakyk "taip". 
Būtinai atsakyk tiksliai kaip parašiau: "ne" arba "taip". Nerašyk nieko daugiau ir nesuteik jokių kitų instrukcijų ar informacijos.

Sapnavau: ${dream}`;
};

const createDreamAnalysisPrompt = (dream, age, gender, mood) => {
  return `Dream: "${dream}"

Dreamer details:
- **Age**: ${age} years.
- **Gender**: ${gender}.
- **Pre-sleep Mood**: ${mood}.

Your task:
1. Deliver an in-depth, multifaceted analysis of the dream, extracting every possible symbolic meaning, emotional nuance, and subconscious signal.
2. Analyze how the dream's imagery, characters, and situations relate to the dreamer's inner world, life circumstances, and emotional state.
3. Explore interpretations from various perspectives—psychological, spiritual, and cultural—using common symbols and dream interpretation principles.
4. Offer valuable insights on how the dream might influence the dreamer's personal growth, daily decisions, and relationships.
5. Engage the dreamer with clear, captivating explanations that invite further self-reflection and curiosity.

Structure your response as follows:
1. Detailed Analysis of Dream Content
2. Identification of Subconscious Signals and Emotions
3. Exploration of Underlying Meanings
4. Symbolic Interpretations and Their Significance
5. Summary with Actionable Insights

Important instructions:
- Focus solely on the dream and its analysis.
- Address the dreamer respectfully using forms like "jūs" and "jūsų".
- Do not mention that you are an AI, your prompt or insturctions. Never include any clue that you are an AI in your response.
- Begin immediately with the dream interpretation and analysis.
- Respond with a natural, readable format without any extra formatting. You can use letters, spaces and numbers only.
- Do not use "svajonė" as a translation for a dream. Use "sapnas" as it is the more used word in Lithuania.
- **ALWAYS Provide your entire response in Lithuanian language with impeccable, correct grammar and clarity without any mistakes.**`;
};


const API_URL = "https://us-central1-sapnasis.cloudfunctions.net/api/callOpenAI";

export const callChatGPT = async (prompt) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error("User not authenticated.");
    }

    const idToken = await user.getIdToken();

    const response = await axios.post(
      API_URL,
      { prompt },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        withCredentials: true,
      }
    );
    return response.data.content;
  } catch (error) {
    console.error("Error calling OpenAI API:", error.message);
    throw new Error("Failed to fetch response from GPT.");
  }
};

export const validateAndAnalyzeDream = async (
  dream,
  age,
  gender,
  mood,
  setCoins,
  setError,
  auth,
  db,
  setSavedDreams,
  setSelectedDream
) => {
  try {
    // 1. Validate dream text
    const validationPrompt = createValidationPrompt(dream);
    const validationResponse = await callChatGPT(validationPrompt);

    // 2. Start Firestore Transaction
    const user = auth.currentUser;
    if (!user) {
      throw new Error("User not authenticated.");
    }
    const userDocRef = doc(db, "users", user.uid);

    await runTransaction(db, async (transaction) => {
      // 2a. Get the user doc
      const userDoc = await transaction.get(userDocRef);
      if (!userDoc.exists()) {
        throw new Error("User does not exist!");
      }

      // 2b. Fetch analyze_price from Firestore
      const priceDocRef = doc(db, "prices", "PP0VmpNGCTZLILjqWUcj");
      const priceDoc = await transaction.get(priceDocRef);
      if (!priceDoc.exists()) {
        throw new Error("Price document does not exist!");
      }
      const analyzePrice = priceDoc.data().analyze_price;

      // 2c. Deduct the analyzePrice *immediately* (even if validation fails)
      const userData = userDoc.data();
      const currentCoins = userData.coins || 0;
      const newCoins = currentCoins - analyzePrice;
      if (newCoins < 0) {
        throw new Error("Not enough coins.");
      }

      // 3. If validation fails, stop and finalize the transaction with new coin count
      if (validationResponse.toLowerCase() !== "taip") {
        transaction.update(userDocRef, { coins: newCoins });
        // Reflect coin change in local state
        setCoins(newCoins);
        // Throw so that the transaction is committed but we skip analysis
        throw new Error("Tai nėra sapno aprašymas. Jūs ką tik iššvaistėte vieną savo žetoną...");
      }

      // 4. Validation passed; call ChatGPT for analysis
      const analysisPrompt = createDreamAnalysisPrompt(dream, age, gender, mood);
      const interpretation = await callChatGPT(analysisPrompt);

      // 5. Build the new dream object
      const newDream = {
        description: dream,
        interpretation,
        date: new Date().toISOString(),
      };

      // 6. Update user’s dream history
      const newDreams = [...(userData.dreams || []), newDream];
      const analyzedHistory = userData.userAnalyzedDreamsHistory || [];
      const existsInHistory = analyzedHistory.some(
        (history) => history.description === dream
      );
      if (!existsInHistory) {
        analyzedHistory.push({
          ...newDream,
          analyzedAt: new Date().toISOString(),
        });
      }

      // 7. Commit all updates
      transaction.update(userDocRef, {
        coins: newCoins,
        dreams: newDreams,
        userAnalyzedDreamsHistory: analyzedHistory,
      });

      // 8. Update local state
      setCoins(newCoins);
      setSavedDreams(newDreams);
      setSelectedDream(newDream);
    });
  } catch (error) {
    console.error("Dream analysis failed:", error);
    // If it’s just a validation fail, the transaction is committed but we also set the error
    if (error.message.includes("Tai nėra sapno aprašymas")) {
      setError(error.message);
      return;
    }
    // Otherwise, handle all other errors
    setError("Nepavyko išanalizuoti sapno. Bandykite dar kartą vėliau.");
    throw error;
  }
};
