import React, { useState, useEffect } from "react";
import "../styles/Login.css"; // Import the CSS file
import LoginCard from "../components/LoginCard";
import RegisterCard from "../components/RegisterCard";
import logo from "../images/LogoBig.png"; // Use the provided logo image
import { analytics } from "../firebase"; // Adjust path if needed
import { logEvent } from "firebase/analytics";




function Login() {
  const [isRegister, setIsRegister] = useState(true);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: "", text: "" });

  const handleOpen = async (title, fileName) => {
    try {
      const content = await import(`../content/${fileName}`);
      const text = await fetch(content.default).then((response) =>
        response.text()
      );
      setModalContent({ title, text });
      setOpen(true);
    } catch (error) {
      console.error("Error loading modal content:", error);
      setModalContent({ title: "Error", text: "Failed to load content." });
      setOpen(true);
    }
  };

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'Login Page',
    });
  }, []);
  

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="login-page">
      <div className="logo-container">
        <img src={logo} alt="Sapnasis Logo" className="logo" />
        <h1 className="welcome-text">
          {isRegister ? "Pradėkime jūsų kelionę!" : "Sveiki sugrįžę!"}
        </h1>
      </div>
      <div className="auth-card">
        {isRegister ? (
          <RegisterCard setIsRegister={setIsRegister} />
        ) : (
          <LoginCard setIsRegister={setIsRegister} />
        )}
      </div>
      <p className="policy-text">
        Registruojantis ir naudojant aplikaciją sutinkate su{" "}
        <button
          className="policy-link"
          onClick={() => handleOpen("Privatumo politika", "privacyPolicy.txt")}
        >
          privatumo politika
        </button>
        ,{" "}
        <button
          className="policy-link"
          onClick={() => handleOpen("Naudojimo sąlygos", "termsOfUse.txt")}
        >
          naudojimo taisyklėmis
        </button>{" "}
        ir{" "}
        <button
          className="policy-link"
          onClick={() => handleOpen("Grąžinimų politika", "refundPolicy.txt")}
        >
          grąžinimo politika
        </button>
        .
      </p>

      {open && (
        <div className="modal-overlay" onClick={handleClose}>
          <div className="modal-card" onClick={(e) => e.stopPropagation()}>
            <button className="modal-close" onClick={handleClose}>
              <i className="fas fa-times"></i>
            </button>
            <div className="modal-header">
              <h2>{modalContent.title}</h2>
            </div>
            <div className="modal-body">
              <p>{modalContent.text}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
