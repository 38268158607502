import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import { auth } from './firebase'; // Import the initialized analytics and auth instances
import { onAuthStateChanged } from 'firebase/auth'; // Import Firebase auth listener

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

function AppRoutes() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (user.providerData[0]?.providerId === 'google.com' || user.emailVerified) {
          // If the user signed in with Google or has a verified email, allow access to the dashboard
          navigate('/dashboard');
        } else {
          // If the user signed in with email and their email is not verified, redirect to login
          navigate('/login');
        }
      }
    });

    return () => unsubscribe(); // Cleanup the listener
  }, [navigate, location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
    </Routes>
  );
}

export default App;
