import React, { useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Alert,
  Grid,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import TextField from "@mui/material/TextField";
import VerificationCard from "./VerificationCard";
import { useNavigate } from "react-router-dom";
import googleLogo from "../images/google logo.png";

const WhiteTextField = styled(TextField)(/* Your existing styles */);

const RegisterCard = ({ setIsRegister }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");
  const [loading, setLoading] = useState(false);
  const [showVerificationCard, setShowVerificationCard] = useState(false);
  const navigate = useNavigate();
  const [isTikTok, setIsTikTok] = useState(false);

  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    const isTT = (
      ua.includes("tiktok") ||
      ua.includes("bytedancewebview") ||
      ua.includes("musical_ly") ||
      ua.includes("ttnet") ||
      ua.includes("com.ss") ||
      ua.includes("trill")
    );
    setIsTikTok(isTT);
  }, []);

  const handleRegister = async () => {
    if (!email || !password || !confirmPassword) {
      setMessage("Prašome užpildyti visus laukus");
      setMessageType("error");
      return;
    }

    if (password !== confirmPassword) {
      setMessage("Slaptažodžiai nesutampa");
      setMessageType("error");
      return;
    }

    setLoading(true);
    setMessage("");

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await sendEmailVerification(user);

      // Add user data to Firestore
      await setDoc(doc(db, "users", user.uid), { email, coins: 5, dreams: [] });

      setMessage("Paskyra sukurta. Prašome atidaryti patvirtinimo laišką kurį išsiuntėme į jūsų el. paštą.");
      setMessageType("success");

      setShowVerificationCard(true);
    } catch (error) {
      console.error(error);
      if (error.code === "auth/email-already-in-use") {
        setMessage("Šis el. pašto adresas jau naudojamas");
      } else if (error.code === "auth/weak-password") {
        setMessage("Slaptažodis turi turėti bent 6 simbolius");
      } else if (error.code === "auth/invalid-email") {
        setMessage("Klaidingas el.pašto adresas");
      } else {
        setMessage("Įvyko klaida. Bandykite dar kartą");
      }
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
    setMessage("");

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        await setDoc(userDocRef, {
          email: user.email,
          coins: 5,
          dreams: [],
        });
      }

      navigate("/dashboard");
    } catch (error) {
      setMessage("Įvyko klaida. Bandykite dar kartą");
      setMessageType("error");
    } finally {
      setLoading(false);
    }
  };

  const handleVerificationConfirmed = () => {
    setShowVerificationCard(false);
    setIsRegister(false); // Redirect to login after verification
  };

  if (showVerificationCard) {
    return (
      <VerificationCard
        email={email}
        onVerify={handleVerificationConfirmed}
      />
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography component="h1" variant="h5" sx={{ color: "#2C3E50" }}>
        Registruotis
      </Typography>
      <Box component="form" noValidate>
        <WhiteTextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="El. paštas"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 0 }}
        />
        <WhiteTextField
          margin="normal"
          required
          fullWidth
          id="password"
          label="Slaptažodis"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ mb: 0 }}
        />
        <WhiteTextField
          margin="normal"
          required
          fullWidth
          id="confirmPassword"
          label="Patvirtinkite slaptažodį"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          sx={{ mb: 0 }}
        />
        {message && (
          <Alert severity={messageType} sx={{ mt: 2 }}>
            {message}
          </Alert>
        )}
        <Button
          fullWidth
          variant="contained"
          onClick={handleRegister}
          disabled={loading}
          sx={{ mt: 2, mb: 2, backgroundColor: "#fb639c" }}
        >
          {loading ? <CircularProgress size={24} /> : "Registruotis"}
        </Button>

        {/* Separator Line */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            width: "100%",
          }}
        >
          <Divider sx={{ flex: 1, bgcolor: "#2C3E50" }} />
          <Typography sx={{ color: "#2C3E50" }}>ARBA</Typography>
          <Divider sx={{ flex: 1, bgcolor: "#2C3E50" }} />
        </Box>

        {/* Google Sign-In Button */}
        <Button
          fullWidth
          variant="outlined"
          onClick={handleGoogleSignIn}
          disabled={loading || isTikTok} // Disable in TikTok
          sx={{
            mt: 1,
            mb: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
            cursor: isTikTok ? 'not-allowed' : 'pointer',
          }}
        >
          <img
            src={googleLogo}
            alt="Google Logo"
            style={{ height: "25px", width: "25px" }}
          />
          Registruotis su Google
        </Button>

        {/* TikTok Warning Message */}
        {isTikTok && (
          <Typography sx={{ color: "red", mt: 1, textAlign: "center", fontSize: 12 }}>
            Google prisijungimas neveikia TikTok naršyklėje. Atidarykite svetainę per Safari, Chrome ar kitą naršyklę.
          </Typography>
        )}


        <Grid container sx={{ textAlign: "center", gap: 2 }}>
          <Grid item xs={12}>
            <Button
              onClick={() => setIsRegister(false)}
              sx={{ color: "#2C3E50" }}
            >
              Jau turite paskyrą? Prisijungti
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RegisterCard;
